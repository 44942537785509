define([], function () {

  const collapsibleBanner = () => {
    const component = {};

    const _config = {
      elements: {
        swatches: null,
      },
      selectors: {
        colourswatch: '.productBlock_colorSwatches_swatch',
        colourSwatchMoreContainer: '.productBlock_colorSwatches_more',
        colourSwatchMoreTextContainer: '.productBlock_colourSwatches_moreText_container',
        colourSwatchRemainingText: '.productBlock_colourSwatches_remainingText',
        colourSwatchContainer: '.productBlock_colourSwatches',
      },
      classes: {
        loading: 'productBlock_colourSwatchLimit_loading',
      },
      properties: {
        swatchCount: 0,
        swatchCountRemaining: 0,
        swatchLimit: 0,
        swatchMoreTextContainerWidth: 0,
        colourSwatchContainerWidth: 0,
        swatchWidth: 25,
        swatchHeight: 25
      }

    }

    const _init = (element) => {
      component.element = element;
      component.removeLoadingState();
      component.updateSwatches();
      
      component.udpateSwatchCount();
      component.getSwatchContainerWidth();
      component.getSwatchMoreTextWidth();
      component.getSingleSwatchDimensions();
      component.udpateSwatchLimit();
      component.calculateRemainingSwatches();
      component.updateRemainingSwatchesText();
      component.showSwatches();
      return component;
    };

    const _removeLoadingState = () => { 
      component.element.classList.remove(component.config.classes.loading);
    };

    const _showSwatches = () => {
      const swatches = component.config.elements.swatches;

      if (swatches) {
        for (let i = 0; i < component.config.properties.swatchLimit; i++) {
          let swatch = swatches[i];
          swatch.removeAttribute('style');
        }
        component.showMoreText(); // has to be here so that it can be called after the swatches have been shown
      }
    };

    const _showMoreText = () => {
      const moreTextContainer = component.element.querySelector(component.config.selectors.colourSwatchMoreTextContainer);
      if (moreTextContainer && component.config.properties.swatchCountRemaining > 0) {
        moreTextContainer.removeAttribute('style');
        moreTextContainer.removeAttribute('aria-hidden');
      }
    };

    const _updateSwatches = () => {
      const swatches = component.getSwatches();
      if (swatches) {
        component.config.elements.swatches = swatches;
      }
    };

    const _getSwatches = () => {
      const allSwatches = component.element.querySelectorAll(component.config.selectors.colourswatch);
      return allSwatches;
    };

    const _udpateSwatchCount = () => {
      if (component.config.elements.swatches) {
        component.config.properties.swatchCount = component.config.elements.swatches.length;
      } 
    };

    const _udpateSwatchLimit = () => {
      let swatchLimit = (component.config.properties.colourSwatchContainerWidth - component.config.properties.swatchMoreTextContainerWidth) / (component.config.properties.swatchWidth + 4);
      swatchLimit = Math.floor(swatchLimit); 
      
      if (swatchLimit) {
        if (swatchLimit >= component.config.properties.swatchCount) {
          component.config.properties.swatchLimit = component.config.properties.swatchCount;
        }
        else {
          component.config.properties.swatchLimit = swatchLimit;
        }
      }
    };

    const _calculateRemainingSwatches = () => {
      const swatchLimit = component.config.properties.swatchLimit;
      const swatchCount = component.config.properties.swatchCount;
      const remainingSwatches = swatchCount - swatchLimit;
      component.config.properties.swatchCountRemaining = remainingSwatches;
    };

    const _updateRemainingSwatchesText = () => {
      const remainingSwatches = component.config.properties.swatchCountRemaining;
      const remainingSwatchesText = component.element.querySelector(component.config.selectors.colourSwatchRemainingText);
      if (remainingSwatchesText) {
        remainingSwatchesText.innerText = remainingSwatches;
      }
    };

    const _getSwatchMoreTextWidth = () => {
      const swatchMoreText = component.element.querySelector(component.config.selectors.colourSwatchMoreTextContainer);
      if (swatchMoreText) {
        component.config.properties.swatchMoreTextContainerWidth = swatchMoreText.offsetWidth;
      }
    };

    const _getSwatchContainerWidth = () => {
      component.config.properties.colourSwatchContainerWidth = component.element.offsetWidth;
    };

    const _getSingleSwatchDimensions = () => {
      if (component.config.elements.swatches) {
        const swatch = component.config.elements.swatches[0];
        const swatchWidth = swatch.offsetWidth;
        const swatchHeight = swatch.offsetHeight;
        component.config.properties.swatchWidth = swatchWidth;
        component.config.properties.swatchHeight = swatchHeight;
      }
    };

    component.init = _init;
    component.config = _config;
    component.updateSwatches = _updateSwatches;
    component.removeLoadingState = _removeLoadingState;
    component.showSwatches = _showSwatches;
    component.showMoreText = _showMoreText;
    component.getSwatches = _getSwatches;
    component.udpateSwatchCount = _udpateSwatchCount;
    component.udpateSwatchLimit = _udpateSwatchLimit;
    component.calculateRemainingSwatches = _calculateRemainingSwatches;
    component.updateRemainingSwatchesText = _updateRemainingSwatchesText;
    component.getSwatchMoreTextWidth = _getSwatchMoreTextWidth;
    component.getSwatchContainerWidth = _getSwatchContainerWidth;
    component.getSingleSwatchDimensions = _getSingleSwatchDimensions;

    return component;
  };

  return collapsibleBanner;
});
